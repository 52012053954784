<template>
  <div class="trainingWrap container">
    <header>
      <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap"><img src="../../assets/image/homeImg/logo.png" /></div>
            <div class="app-title" @click="$router.push('/home')">在线培训平台</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="nav_side">
        <nav>
          <router-link active-class="active" to="/home">首页</router-link>
          <router-link active-class="active" to="/trainingClass">安全培训</router-link>
          <router-link active-class="active" to="/trainingClass2">职业卫生</router-link>
          <router-link active-class="active" to="/studyCenter">学习中心</router-link>
          <router-link active-class="active" to="/examCenter">考试中心</router-link>
<!--          <router-link active-class="active" to="/newcomerPost">新人驿站</router-link>-->
<!--          <router-link active-class="active" to="/liveRoom">直播大厅</router-link>-->
<!--          <router-link active-class="active" to="/boutiqueCourse">精品课程</router-link>-->
          <router-link active-class="active" to="/trainDynamic">培训动态</router-link>
          <router-link active-class="active" to="/helpCenter">帮助中心</router-link>
        </nav>
      </div>
    </header>
    <div class="main-content">
      <div class="content">
        <div class="studentInfoCard">
          <div class="examName">{{ allQuestionData.paperTitle }}</div>
          <div class="baseInfo">
            <div class="userPhoto"><img :src="this.userInfo.photoUrl ? this.userInfo.photoUrl : require('../../assets/examCenterUser.png')" alt="" /></div>
            <div class="info-content">
              <div class="name">姓名：{{ userName }}</div>
              <div class="workplace">单位：{{ this.userInfo.gzdw }}</div>
              <div class="name">职务：{{ this.userInfo.zhiwu }}</div>
            </div>
          </div>
          <div class="lastExamTime"></div>
          <!-- 答题时候题号 -->
          <div v-for="(item, index) in allQuestionData && allQuestionData.answerSheet && allQuestionData.answerSheet.sectionList" :key="index">
            <div class="otherInfo">{{ getQuestionType(item.questionType) }}</div>
            <div class="answerList" v-if="answerType =='start'">
              <div
                class="answerNum"

                v-for="(item2, index2) in item && item.questionList"
                :key="index2"
                @click="jumpExamItem(item2)"
              >
              <div class="answerCircle" :class="{ sheetActive: item2.isSubmited == 'Y' }">
                {{ index2 + 1 }}
              </div>

              </div>
            </div>
            <!-- 结果展示的题号 -->
            <div class="answerList" v-else>
              <div
                class="answerNum"
                v-for="(item2, index2) in item && item.questionList"
                :key="index2"
                @click="jumpExamItem(item2)"
              >
                <div class="answerCircle" :class="item2.isSubmited == 'Y' && item2.studentResult == 'RIGHT'?'sheetActive':item2.isSubmited == 'Y' && item2.studentResult != 'RIGHT'?'errorActive':''">
                  {{ index2 + 1 }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="examList">
          <div class="countDown"><div v-if="this.answerType =='start' && !isSubmitOk">倒计时：<span>{{downTime}}</span></div></div>
          <div class="examItemContent" v-if="!isSubmitOk">
            <!-- 题干部分 -->
            <div class="answerNumDiv">
              <div class="answerType">{{ this.getQuestionType(nowQuestion.questionType) }}</div>
              <div class="titleWrap"><span class="bigNum">{{ nowQuestionIndex + 1 }}</span> / <span class="bigNum smallNum">{{ allQuestionData && allQuestionData.questionAmount }}</span></div>
            </div>
            <div class="teamPart">{{ nowQuestionIndex + 1 }}.{{ nowQuestion.questionTitle }}</div>
            <!-- 选项部分 -->
            <div class="respondPart">
              <!-- 单选题 -->
              <div class="optionType" v-if="nowQuestion.questionType == 'SINGLE'">
                <el-radio-group :disabled="answerType =='end'" v-model="chouseRadio" placement="column" @change="groupChange">
                  <el-radio :customStyle="{ marginBottom: '8px' }" v-for="(item, index) in nowQuestion.optionList" :key="index" :label="item.id" :name="item.id">
                    {{ item.optionValue +'  ' + item.optionDesc }}
                  </el-radio>
                </el-radio-group>
              </div>
              <!-- 多选题 -->
              <div class="optionType" v-if="nowQuestion.questionType == 'MUTIL'">
                <el-checkbox-group :disabled="answerType =='end'" v-model="answerList" placement="column" @change="checkboxChange">
                  <el-checkbox :customStyle="{ marginBottom: '8px' }" v-for="(item, index) in nowQuestion.optionList" :key="index" :label="item.id" :name="item.id">
                    {{ item.optionValue +'  ' + item.optionDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <!-- 判断题 -->
              <div class="optionType" v-if="nowQuestion.questionType == 'TRUEFALSE'">
                <el-radio-group :disabled="answerType =='end'" v-model="chouseRadio" placement="row" @change="groupChange">
                  <el-radio :customStyle="{ marginRight: '18px' }" v-for="(item, index) in nowQuestion.optionList" :key="index" :label="item.id" :name="item.id">
                    {{ item.optionDesc }}
                  </el-radio>
                </el-radio-group>
              </div>
              <!-- 填空题 -->
              <div class="inputType" v-if="nowQuestion.questionType == 'CLOZE'">
                <el-input
                 :disabled="answerType =='end'"
                  v-for="(item, index) in nowQuestion.optionList"
                  :key="index"
                  :placeholder="getPlaceholderText(index)"
                  border="surround"
                  v-model="clozeAnswerList[index]"
                  @change="changeInput($event, index)"
                ></el-input>
              </div>
              <!-- 问答题 -->
              <div class="inputType" v-if="nowQuestion.questionType == 'DISCUSS'">
                <el-input :disabled="answerType =='end'" type="textarea" :rows="3" v-model="discussAnswer" placeholder="请输入内容"></el-input>
              </div>
            </div>
            <!-- 答案部分 -->
            <div class="answerPart" v-if="answerType == 'end'">
              <div class="rightAnswer" v-if="nowQuestion.studentResult == 'RIGHT'">答题正确</div>
              <view class="rightAnswer error" v-if="nowQuestion.studentResult == 'LESS' || nowQuestion.studentResult == 'ERROR'">
                  <view v-if="nowQuestion.questionType == 'SINGLE' || nowQuestion.questionType == 'MUTIL'">
                      正确答案: <span v-for="(item, index) in nowQuestion.optionList" :key="index">
                          <span v-if="item.isStdAnswer == 'Y'">{{item.optionValue}}</span>
                      </span>
                  </view>
                  <view v-else-if="nowQuestion.questionType == 'TRUEFALSE'">
                      正确答案: <span v-for="(item, index) in nowQuestion.optionList" :key="index">
                          <span v-if="item.isStdAnswer == 'Y'">{{item.optionDesc}}</span>
                      </span>
                  </view>
                  <view v-else>
                      答题错误
                  </view>
              </view>
              <div class="analysis">解析：<span v-html="nowQuestion.questionAnalysis"></span></div>
            </div>
          </div>
          <div class="switchExam" v-if="!isSubmitOk">
            <div class="nextOrBack">
              <div v-if="nowQuestionIndex != 0" class="lastStep disabledStep" @click="step('back')">上一题</div>
              <div v-else></div>
              <div class="nextStep" v-if="nowQuestionIndex != allQuestionData.questionAmount - 1" @click="step('next')">下一题</div>
              <div class="submit" v-if="(nowQuestionIndex == allQuestionData.questionAmount - 1) && answerType == 'start'" @click="newexamSaveAnswer('submit')">交卷</div>
            </div>
          </div>
          <div class="submitOk" v-if="isSubmitOk">
            <div class="textDiv">
              <p class="redColor">恭喜您完成本次考试！</p>
              <p class="blackColor">考试界面将于{{secondNum}}秒后自动退出……</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
export default {
  name: '',
  components: {
    footerCom
  },
  props: {},
  watch: {},
  data () {
    return {
      oldQuestionIndex: 0,
      secondNum: 10,
      downTime: '',
      answerType: '',
      userName: '',
      userInfo: {},
      isSubmitOk: false,
      chouseRadio: '',
      answerList: [],
      clozeAnswerList: [],
      discussAnswer: '',
      allQuestionData: '',
      nowQuestionIndex: 0,
      nowQuestion: '',
      examInfoId: '',
      examName: '',
      examId: '',
      activeName: 'first',
      chooseIndex: 0,
      pauseCnt: 0
    }
  },
  methods: {
    getPlaceholderText (index) {
      return `请输入第${index + 1}个问题`
    },
    getQuestionType (type) {
      let sta_enum = {
        CLOZE: '填空题',
        DISCUSS: '问答题',
        MUTIL: '多选题',
        SINGLE: '单选题',
        TRUEFALSE: '判断题'
      }
      return sta_enum[type]
    },
    // 获取local中用户信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo') ? JSON.parse(localStorage.getItem('studentInfo')) : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
    getLoadStudentExtend () {
      this.$axios.post(this.$apiUrl.loadStudentExtend, {}).then(response => {
        console.log(response)
        if (response.data.success) {
          let res = response.data
          this.userInfo = res.data
        }
      })
    },
    pageJumpLogin () {
      this.$router.push('/login')
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    jumpExamItemPage (item, index) {
      console.log(item.examId)
      this.chooseIndex = index
    },
    // 查看成绩
    examGetStuExam (id) {
      let params = {
        studentExamId: id
      }
      this.$axios.get(this.$apiUrl.examGetStuExam, { params }).then(res => {
        if (res.data.success) {
          this.allQuestionData = res.data.data
          // 给每道题添加是否答过标识
          // this.allQuestionData.questionList.forEach((item, index) => {
          //   this.$set(item, 'isAnswer', this.getIsAnswer(item));
          // });
          // 给每道题添加答题是否正确的标识
          this.allQuestionData.answerSheet.sectionList.forEach((item, index) => {
            item.questionList.forEach((item2, index2) => {
              this.$set(item2, 'studentResult', this.getIsStudentResult(item2))
            })
          })
          this.nextQuestion()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
          setTimeout(() => {
            this.$router.push('/examCenter')
          }, 2000)
        }
      })
    },
    // 判断每道题是否正确
    getIsStudentResult (item2) {
      let isStudentResult = ''
      this.allQuestionData.questionList.forEach((item, index) => {
        if (item2.id == item.id) {
          isStudentResult = item.studentResult
        }
      })
      return isStudentResult
    },
    // 判断每道题是否答过
    getIsAnswer (item) {
      let isAnswer = false
      this.allQuestionData.answerSheet.sectionList.forEach((ite, ind) => {
        if (!isAnswer && item.questionType == ite.questionType) {
          ite.questionList.forEach((iteI, indI) => {
            if (!isAnswer && item.id == iteI.id && iteI.isSubmited == 'Y') {
              isAnswer = true
            }
          })
        }
      })
      return isAnswer
    },
    jumpExamItem (item2) {
      this.allQuestionData.questionList.forEach((item, index) => {
        if (item.id == item2.id) {
          this.oldQuestionIndex = JSON.parse(JSON.stringify(this.nowQuestionIndex))
          this.nowQuestionIndex = index
        }
      })
      this.step('now')
    },
    // 上一题or下一题
    step (code) {
      if (code == 'back') {
        this.nowQuestionIndex--
      } else if (code == 'next') {
        this.nowQuestionIndex++
      }
      if (this.answerType == 'start' && (this.chouseRadio || this.answerList.length > 0 || this.clozeAnswerList.length > 0 || this.discussAnswer)) {
        this.newexamSaveAnswer(code)
      } else {
        this.nextQuestion()
      }
    },
    // 切换
    newexamSaveAnswer (code) {
      let newAnswerList = []
      if (this.nowQuestion.questionType == 'SINGLE' || this.nowQuestion.questionType == 'TRUEFALSE') {
        // 单选or判断
        newAnswerList = [this.chouseRadio]
      } else if (this.nowQuestion.questionType == 'MUTIL') {
        // 多选
        newAnswerList = this.answerList
      }
      let params = {
        answerList: newAnswerList,
        clozeAnswerList: this.clozeAnswerList,
        discussAnswer: this.discussAnswer,
        studentExamId: this.allQuestionData.id,
        studentExamQuestionId: this.nowQuestion.id
      }
      this.$axios.post(this.$apiUrl.newexamSaveAnswer, params).then(res => {
        if (res.data.success) {
          if (code == 'next' || code == 'back') { // 切换题目
            // 切换题目
            // 答题卡已答题记录
            // if(code =='next'){
            //     this.$set(this.allQuestionData.questionList[this.nowQuestionIndex-1], 'isAnswer', true)
            // }else if(code =='back'){
            //     this.$set(this.allQuestionData.questionList[this.nowQuestionIndex+1], 'isAnswer', true)
            // }
            this.allQuestionData.answerSheet.sectionList.forEach((item, index) => {
              item.questionList.forEach((item2, index2) => {
                if (item2.id == this.nowQuestion.id) {
                  this.$set(item2, 'isSubmited', 'Y')
                }
              })
            })
            // 填空题塞数据
            if (this.nowQuestion.questionType == 'CLOZE') {
              this.nowQuestion.optionList.forEach((item, index) => {
                this.clozeAnswerList.forEach((it, ind) => {
                  if (it && index == ind) {
                    this.$set(item, 'optionAnswer', it)
                  }
                })
              })
            }
            // 单选or判断题塞数据
            if (this.nowQuestion.questionType == 'SINGLE' || this.nowQuestion.questionType == 'TRUEFALSE') {
              this.nowQuestion.optionList.forEach(item => {
                if (item.id == this.chouseRadio) {
                  this.$set(item, 'isStuAnswer', 'Y')
                } else {
                  this.$set(item, 'isStuAnswer', 'N')
                }
              })
            }
            // 多选题塞数据
            if (this.nowQuestion.questionType == 'MUTIL') {
              this.nowQuestion.optionList.forEach(item => {
                if (this.answerList.indexOf(item.id) != -1) {
                  this.$set(item, 'isStuAnswer', 'Y')
                } else {
                  this.$set(item, 'isStuAnswer', 'N')
                }
              })
            }
            // 问答题塞数据
            if (this.nowQuestion.questionType == 'DISCUSS') {
              this.$set(this.nowQuestion, 'studentAnswer', this.discussAnswer)
            }
            this.nextQuestion()
          } else if (code == 'now') {
            this.allQuestionData.answerSheet.sectionList.forEach((item, index) => {
              item.questionList.forEach((item2, index2) => {
                if (item2.id == this.nowQuestion.id) {
                  this.$set(item2, 'isSubmited', 'Y')
                }
              })
            })
            // 填空题塞数据
            if (this.nowQuestion.questionType == 'CLOZE') {
              this.nowQuestion.optionList.forEach((item, index) => {
                this.clozeAnswerList.forEach((it, ind) => {
                  if (it && index == ind) {
                    this.$set(item, 'optionAnswer', it)
                  }
                })
              })
            }
            // 单选or判断题塞数据
            if (this.nowQuestion.questionType == 'SINGLE' || this.nowQuestion.questionType == 'TRUEFALSE') {
              this.nowQuestion.optionList.forEach(item => {
                if (item.id == this.chouseRadio) {
                  this.$set(item, 'isStuAnswer', 'Y')
                } else {
                  this.$set(item, 'isStuAnswer', 'N')
                }
              })
            }
            // 多选题塞数据
            if (this.nowQuestion.questionType == 'MUTIL') {
              this.nowQuestion.optionList.forEach(item => {
                if (this.answerList.indexOf(item.id) != -1) {
                  this.$set(item, 'isStuAnswer', 'Y')
                } else {
                  this.$set(item, 'isStuAnswer', 'N')
                }
              })
            }
            // 问答题塞数据
            if (this.nowQuestion.questionType == 'DISCUSS') {
              this.$set(this.nowQuestion, 'studentAnswer', this.discussAnswer)
            }
            this.nextQuestion()
          } else if (code == 'submit') { // 提交
            this.allQuestionData.answerSheet.sectionList.forEach((item, index) => {
              item.questionList.forEach((item2, index2) => {
                if (item2.id == this.nowQuestion.id) {
                  this.$set(item2, 'isSubmited', 'Y')
                }
              })
            })
            // 填空题塞数据
            if (this.nowQuestion.questionType == 'CLOZE') {
              this.nowQuestion.optionList.forEach((item, index) => {
                this.clozeAnswerList.forEach((it, ind) => {
                  if (it && index == ind) {
                    this.$set(item, 'optionAnswer', it)
                  }
                })
              })
            }
            // 单选or判断题塞数据
            if (this.nowQuestion.questionType == 'SINGLE' || this.nowQuestion.questionType == 'TRUEFALSE') {
              this.nowQuestion.optionList.forEach(item => {
                if (item.id == this.chouseRadio) {
                  this.$set(item, 'isStuAnswer', 'Y')
                } else {
                  this.$set(item, 'isStuAnswer', 'N')
                }
              })
            }
            // 多选题塞数据
            if (this.nowQuestion.questionType == 'MUTIL') {
              this.nowQuestion.optionList.forEach(item => {
                if (this.answerList.indexOf(item.id) != -1) {
                  this.$set(item, 'isStuAnswer', 'Y')
                } else {
                  this.$set(item, 'isStuAnswer', 'N')
                }
              })
            }
            // 问答题塞数据
            if (this.nowQuestion.questionType == 'DISCUSS') {
              this.$set(this.nowQuestion, 'studentAnswer', this.discussAnswer)
            }
            this.$confirm('此操作将提交试卷?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$message({
                type: 'success',
                message: '已提交试卷!'

              })
              this.submit()
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消提交'
              })
            })
          }
        }
      })
    },
    submit () {
      let params = {
        id: this.allQuestionData.id
      }
      this.$axios.post(this.$apiUrl.examSubmitExam, params).then(res => {
        if (res.data.success) {
          this.isSubmitOk = true
          this.getSecondNum(this.secondNum)
          // this.$message({
          //   message: '试卷提交成功。',
          //   type: 'success',
          // })
          // setTimeout(() => {
          //
          // }, 1500)
        }
      })
    },
    getSecondNum (secondNum) {
      if (secondNum === 0) {
        this.$router.push('/examCenter')
        return
      } else {
        this.secondNum--
      }
      setTimeout(() => {
        this.getSecondNum(this.secondNum)
      }, 1000)
    },
    nextQuestion () {
      this.chouseRadio = ''
      this.answerList = []
      this.clozeAnswerList = []
      this.discussAnswer = ''
      this.nowQuestion = this.allQuestionData.questionList[this.nowQuestionIndex]
      this.$nextTick(() => {
        // 填空题回显
        if (this.nowQuestion.questionType == 'CLOZE') {
          this.nowQuestion.optionList.forEach(item => {
            this.clozeAnswerList.push(item.optionAnswer ? item.optionAnswer : '')
          })
        }
        // 回显单选or判断题
        if (this.nowQuestion.questionType == 'SINGLE' || this.nowQuestion.questionType == 'TRUEFALSE') {
          this.nowQuestion.optionList.forEach(item => {
            if (item.isStuAnswer && item.isStuAnswer == 'Y') {
              this.chouseRadio = item.id
            }
          })
        }
        // 回显多选题
        if (this.nowQuestion.questionType == 'MUTIL') {
          this.nowQuestion.optionList.forEach(item => {
            if (item.isStuAnswer && item.isStuAnswer == 'Y') {
              this.answerList.push(item.id)
            }
          })
        }
        // 回显问答题
        if (this.nowQuestion.questionType == 'DISCUSS') {
          if (this.nowQuestion.studentAnswer) {
            this.discussAnswer = this.nowQuestion.studentAnswer
          }
        }
      })
    },
    groupChange (n) {
      this.chouseRadio = n
    },
    checkboxChange (n) {
      this.answerList = n
    },
    changeInput (event, index) {
      console.log('changeInput', event, index)
      this.clozeAnswerList[index] = event
    },
    // 获取问题
    examEntryExam (examInfoId) {
      let params = {
        examInfoId: examInfoId
      }
      this.$axios.get(this.$apiUrl.examEntryExam, { params }).then(res => {
        if (res.data.success) {
          this.allQuestionData = res.data.data
          // 给每道题添加是否答过标识
          this.allQuestionData.questionList.forEach((item, index) => {
            this.$set(item, 'isAnswer', this.getIsAnswer(item))
          })
          if (this.answerType == 'start') {
            // 倒计时
            this.getDownTime(this.allQuestionData.remainSeconds<this.allQuestionData.seconds ? this.allQuestionData.remainSeconds : this.allQuestionData.seconds)
          }
          this.nextQuestion()
          // 监听是否切屏 isPauseOverstepSubmit: Y 切屏   N:不判断切屏
          if (this.allQuestionData.isPauseOverstepSubmit == 'Y' && this.answerType == 'start') {
            this.pauseCnt = this.allQuestionData.pauseCnt
            if (this.allQuestionData.pauseCnt >= this.allQuestionData.maxPauseCnt) {
              // 直接交卷
              this.$message({
                type: 'info',
                message: `您切屏已经超过${this.allQuestionData.maxPauseCnt}次,直接交卷！`
              })
              this.submit()
            } else {
              // 监听页面可见性
              window.addEventListener('visibilitychange', this.pageHidden)
            }
          }
          // 测试用
          // window.addEventListener('visibilitychange', this.pageHidden)
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
          setTimeout(() => {
            this.$router.push('/examCenter')
          }, 2000)
        }
      })
    },
    pageHidden () {
      let state = document.visibilityState
      if (state == 'hidden') {
        this.pauseCnt++

        let params = {
          id: this.allQuestionData.id,
          pauseCnt: this.pauseCnt
        }
        this.$axios.post(this.$apiUrl.updatePauseCnt, params).then(res => {
          if (this.pauseCnt >= this.allQuestionData.maxPauseCnt) {
            // 直接交卷
            this.$message({
              type: 'info',
              message: `您切屏已经超过${this.allQuestionData.maxPauseCnt}次,直接交卷！`
            })
            this.submit()
          } else {
            this.$message({
              type: 'info',
              message: '考试过程中请勿切屏！'
            })
          }
        })
      } else {

      }
    },
    getDownTime (secondTime) {
      if (secondTime == 0 || secondTime < 0) {
        this.downTime = '00:00'
        this.$message({
          type: 'info',
          message: '考试结束，为您自动提交试卷！'

        })
        // 提交试卷
        this.submit()
        return
      } else {
        let minutes = Math.floor(secondTime / 60).toString().length == 1 ? '0' + Math.floor(secondTime / 60) : Math.floor(secondTime / 60)
        let seconds = (secondTime % 60).toString().length == 1 ? '0' + secondTime % 60 : secondTime % 60
        this.downTime = minutes + ':' + seconds
        secondTime--
      }
      setTimeout(() => {
        this.getDownTime(secondTime)
      }, 1000)
    },
  },
  mounted () {
    this.getUserInfo()
    this.getLoadStudentExtend()
    if (this.$route.query) {
      console.log('1111111==', this.$route.query)
      this.answerType = this.$route.query.code
      if (this.$route.query.code == 'start') {
        // 考试中
        this.examEntryExam(this.$route.query.examInfoId)
      } else if (this.$route.query.code == 'end') {
        // 考试结束，答题详情以及答案
        this.examGetStuExam(this.$route.query.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;

  header {
    background: url('../../assets/image/homeImg/headerBg.png');
    background-size: 100% 100%;
    height: 231px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 56px;
    width: 1240px;
    margin: 0 auto;
    line-height: 56px;
    /* padding: 0 100px; */

    .header-left-content {
      font-size: 22px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 45px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 56px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 34px;
        line-height: 34px;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      ::v-deep .el-dropdown {
        font-size: 18px;
        color: #4774df;
        background-color: #fff;
        height: 34px;
        line-height: 34px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }

  .nav_side {
    width: 1240px;
    margin: 0 auto;
    height: 61px;
    line-height: 61px;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 22px;
      font-weight: bold;
    }

    nav a {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 61px;
    }

    nav a:hover {
      opacity: 1;
    }

    .active {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      border-bottom: 3px solid #ffffff;
    }
  }
}
.main-content {
  width: 1440px;
  padding-bottom: 188px;
  margin: -84px auto 0;
  background-color: transparent;
  .content {
    margin-bottom: 50px;
    width: 1440px;
    display: flex;
    justify-content: space-around;
    .studentInfoCard {
      width: 377px;
      background: #ffffff;
      padding: 30px 20px;
      box-shadow: 0px 6px 30px 1px rgba(0, 0, 0, 0.12);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid rgba(0, 0, 0, 0);
      font-size: 22px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #1c4ecc;
      .examName {
        height: 30px;
        line-height: 30px;
        opacity: 1;
        margin-bottom: 20px;
      }
      .baseInfo {
        display: flex;
        align-items: center;
        .userPhoto {
          width: 137px;
          height: 200px;
          border: 1px solid #707070;
          img {
            width: 135px;
            height: 198px;
          }
        }
        .info-content {
          font-size: 20px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          margin-left: 20px;
          div {
            margin-bottom: 20px;
          }
        }
      }
      .lastExamTime {
        padding: 20px 0px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        border-bottom: 1px dashed #707070;
      }
      .otherInfo {
        margin: 10px 0;
        padding-left: 10px;
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        border-left: 2px solid #5b75a8;
      }
      .answerList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .answerNum {
          flex: 0 0 20%;
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          .answerCircle{
            cursor: pointer;
            width: 26px;
            height: 26px;
            border-radius: 13px;
            background: #ffffff;
            border: 1px solid #707070;
            margin-right: 4px;
            line-height: 26px;
            text-align: center;
            font-size: 18px;
            margin-bottom: 4px;
            color: #212121;
          }
          .sheetActive {
            background: #5c88fe;
            color: #fff;
            border: 0px;
          }
          .errorActive{
            background: #FB0000;
            color: #fff;
            border: 0px;
          }
        }

      }
    }
    .examList {
      width: 872px;
      min-height: 753px;
      background: #ffffff;
      box-shadow: 0px 6px 30px 1px rgba(0, 0, 0, 0.12);
      border-radius: 20px 20px 20px 20px;
      opacity: 1;
      border: 1px solid rgba(0, 0, 0, 0);
      position: relative;
      .countDown {
        padding: 0 30px;
        height: 48px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.2);
        text-align: right;
        font-size: 16px;
        display: flex;
        justify-content: end;
        align-items: center;
      }
      .examItemContent {
        padding: 20px 24px 40px 30px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        .answerNumDiv{
          display: flex;
          justify-content: space-between;
          .answerType {
            max-width: 120px;
            font-size: 20px;
            margin-bottom: 10px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #0090ff;
            padding: 4px 24px;
            background: rgba(203, 232, 255, 0.51);
            border-radius: 8px 8px 8px 8px;
          }
          .titleWrap{
            padding: 0 30px;
            height: 48px;
            // border-bottom: 1px solid rgba(112, 112, 112, 0.2);
            text-align: right;
            font-size: 16px;
            display: flex;
            justify-content: end;
            align-items: center;
            .bigNum{
              font-size: 18px;
              font-weight: bold;
            }
            .smallNum{
              font-size: 14px;
            }
          }
        }

        .respondPart {
          .optionType {
            margin-top: 20px;
            .el-radio-group{
              display: flex;
              flex-direction: column;
              .el-radio{
                height: 36px;
                line-height: 36px;
                ::v-deep.el-radio__label{
                  font-size: 16px;
                }
              }
            }
            .el-checkbox-group{
              display: flex;
              flex-direction: column;
              .el-checkbox{
                height: 36px;
                line-height: 36px;
                ::v-deep.el-checkbox__label{
                  font-size: 16px;
                }
              }
            }
            .optionitem {

              align-items: center;
              padding-bottom: 34rpx;

              .optionitem-left {
                width: 54rpx;
                height: 54rpx;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ffffff;
                opacity: 1;
                border: 2rpx solid #707070;
                font-size: 32rpx;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                border-radius: 100%;
                margin-right: 34rpx;
              }
              .chooseActive {
                background: #0090ff;
                color: #fff;
                border: 2rpx solid #0090ff;
              }
              .optionitem-right {
                display: flex;

                text {
                  width: 34rpx;
                  height: 34rpx;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #ffffff;
                  opacity: 1;
                  border: 2rpx solid #707070;
                  font-size: 24rpx;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  border-radius: 100%;
                  padding: 5rpx;
                }
              }
            }
          }
          .inputType {
            margin-top: 20px;
            .el-input {
              margin-bottom: 10px;
            }
          }
        }
        .answerPart {
          margin-top: 40px;
          .rightAnswer {
            font-size: 20px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #0090ff;
            margin-bottom: 28px;
          }
          .error {
            color: #fb0000;
          }

          .analysis {
            font-size: 20px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #0090ff;
          }
        }
      }
      .switchExam {
        position: absolute;
        bottom: 20px;
        width: 100%;
        padding: 0 20px;
        .nextOrBack {
          display: flex;
          padding-top: 200px;
          padding-bottom: 100px;
          justify-content: space-between;
          div {
            font-size: 28px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #0090ff;
            cursor: pointer;
          }
          .disabledStep {
            color: #000000;
          }
        }
      }
      .submitOk{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        .textDiv{
          margin-top: 100px;
          .redColor{
            color: #c9262d;
            font-size: 18px;
            font-weight: 600;
          }
          .blackColor{
            color: #707070;
            font-size: 14px;
            font-weight: 500;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>
